export const environment = {
    production: false,
    apiUrl: 'https://admap.dev.mylocalcoin.io',
    apiWebsocket: 'wss://ws.dev.mylocalcoin.io/chanex/',
    //apiUrl: 'http://localhost:5000',
    //apiWebsocket: 'ws://localhost:4000/chanex/',
    imgServer: 'https://static.mylocalcoin.io',
    report: 'https://static.mylocalcoin.io',
    googleApiKey:'AIzaSyCL207wDz0PAnXYnXcPj_EUHijUFB3CqVc'
};
